<template>
  <div class="status-item">
    <div
      class="status-wrap"
      :class="className"
      :style="{
        color: color,
        backgroundColor: bgColor,
        border: `1px solid ${borderColor}`,
      }"
    ></div>
    <div class="description">{{ text }}</div>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    color: String,
    bgColor: String,
    borderColor: String,
    className: String,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.status-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: auto !important;
  margin: 0 10px;
  border: none !important;
  .status-wrap {
    width: 30px;
    height: 18px;
    border-radius: 4px;
    margin-right: 4px;
  }
  .description {
    color: #333;
  }
  .white {
    background-color: #fff;
    color: #07141e;
    border: 1px solid #ccc;
  }
  .red {
    background-color: #fc947e;
  }
  .yellow {
    background-color: #ccd652;
  }
  .darkYellow {
    background-color: #d9b42a;
  }
  .green {
    background-color: #45d534;
  }
  .grey {
    background-color: #c5c0bc;
  }
}
</style>
