<template>
  <div>
    <el-input v-if="false" v-model="imagesGroup" />
    <!--    imageUrl-->
    <el-button :disabled="!(dataListSelections.length === 1)" @click="delePic"
      >删除</el-button
    >
    <el-button :disabled="dataListSelections.length === 0" @click="delePic"
      >批量删除</el-button
    >
    <el-table
      border
      :data="imagesGroup"
      style="width: 100%"
      @selection-change="selectionChangeHandle"
    >
      <el-table-column
        v-if="!disabled"
        type="selection"
        header-align="center"
        align="center"
        width="50"
      />
      <el-table-column header-align="center" align="center" label="图片">
        <template slot-scope="scope">
          <img
            :src="scope.row"
            style="width: 50px"
            alt=""
            @click="openImg(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-button
      v-if="!disabled"
      icon="el-icon-upload"
      circle
      @click="openDialog"
    />
    <el-dialog
      :visible.sync="visible"
      style="z-index: 1000"
      width="950px"
      title="选择图片"
      append-to-body
      @close="closeHandle"
    >
      <el-form :inline="true" @keyup.enter.native="getDataList()">
        <el-form-item>
          <el-button type="primary" @click="uploadHandle()">
            上传文件
          </el-button>
        </el-form-item>
      </el-form>
      <el-row>
        <el-table
          border
          ref="piclist"
          :data="dataList"
          style="width: 100%"
          @selection-change="selectionImgChangeHandle"
          :row-key="getRowKeys"
        >
          <el-table-column
            type="selection"
            :reserve-selection="true"
            header-align="center"
            align="center"
            width="50"
          />
          <el-table-column header-align="center" align="center" label="图片">
            <template slot-scope="scope">
              <img
                :src="scope.row.url"
                style="width: 50px"
                alt=""
                @click="openImg(scope.row.url)"
              />
            </template>
          </el-table-column>
          <el-table-column header-align="center" align="center" label="操作">
            <template slot-scope="scope">
              <el-button
                v-if="isAuth('tc:licenseoss:delete')"
                type="text"
                icon="el-icon-delete"
                @click="deleteHandle(scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-pagination
        :current-page="pageIndex"
        :page-sizes="[12, 18, 24, 48]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
      />
      <span slot="footer">
        <el-button @click="visible = false">关闭</el-button>
        <el-button @click="makeHandle">确定</el-button>
      </span>
    </el-dialog>
    <!-- 弹窗, 上传文件 -->
    <upload v-if="uploadVisible" ref="upload" @refreshDataList="getDataList" />
  </div>
</template>

<script>
import Upload from '../../views/modules/tc/license-oss-upload';

export default {
  name: 'ElImgs',
  componentName: 'ElImgs',
  components: {
    Upload,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    // 接受外部v-model传入的值，必须使用value
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dataList: [],
      imagesGroup: [],
      dataListSelections: [],
      imgdataListSelections: [],
      pageIndex: 1,
      pageSize: 12,
      totalPage: 0,
      visible: false,
      uploadVisible: false,
      imageUrl: '',
    };
  },
  watch: {
    imagesGroup(val, oldVal) {
      if (val !== oldVal) {
        this.$emit('input', this.imagesGroup);
      }
    },
    value(val) {
      this.imagesGroup = val;
    },
  },
  mounted() {
    this.getDataList();
    this.imagesGroup = this.value;
  },
  methods: {
    // 上传文件
    uploadHandle() {
      this.uploadVisible = true;
      this.$nextTick(() => {
        this.$refs.upload.init();
      });
    },
    //删除新增(修改)窗口中的图片
    delePic() {
      this.dataListSelections.forEach((sitem) => {
        this.imagesGroup.forEach((item, index) => {
          if (item === sitem) {
            this.imagesGroup.splice(index, 1);
          }
        });
      });
    },
    //打开多选图片窗口
    openDialog() {
      this.visible = true;
      this.currentChangeHandle(1);
    },
    //关闭多选图片窗口
    closeHandle() {
      this.$refs.piclist.clearSelection();
    },
    //返回多选图片
    makeHandle() {
      // //PLAN A
      // this.imagesGroup = this.imgDataListSelections.map(i => {return i.url});

      // PLAN B
      let deleteSame = [];
      let flag;
      if (this.imgDataListSelections?.length) {
        this.imgDataListSelections.forEach((i) => {
          flag = true;
          this.imagesGroup.forEach((si) => {
            if (i.url == si) {
              flag = false;
            }
          });
          if (flag == true) {
            deleteSame.push(i.url);
          }
        });
      }
      this.imagesGroup = this.imagesGroup.concat(deleteSame);
      this.visible = false;
    },
    // 获取数据列表
    getDataList() {
      this.$http({
        url: '/tc/licenseoss/list',
        method: 'get',
        params: {
          page: this.pageIndex,
          limit: this.pageSize,
        },
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.page.records;
          this.totalPage = data.page.total;
          //PLAN A
          // this.$nextTick(() => {
          //   // this.$refs.piclist.clearSelection();
          //   this.dataList.forEach(i => {
          //     this.imagesGroup.forEach(s => {
          //       if (i.url === s) {
          //         this.$refs.piclist.toggleRowSelection(i, true)
          //       }
          //     })
          //   })
          // })
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
      });
    },
    // 删除
    deleteHandle(id) {
      let ids = [id];
      this.$confirm('确定删除操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$http({
            url: '/tc/licenseoss/delete',
            method: 'post',
            data: ids,
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.getDataList();
            }
          });
        })
        .catch(() => {});
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    selectionImgChangeHandle(val) {
      this.imgDataListSelections = val;
    },
    //翻页保留选项
    getRowKeys(row) {
      return row.id;
    },
  },
};
</script>
