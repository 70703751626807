<template>
  <div class="footer-container">
    <div class="status-container">
      <slot></slot>
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.footer-container {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  min-height: 40px;
  .status-container {
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
    .right {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
</style>
